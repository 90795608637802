.custom-alert {
    width: 396px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    padding: 10px;
    position: relative;
  }
  
  .custom-alert-text {
    flex: 1;
  }
  
  .close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
  }
  
  .slick-list {
    text-align: -webkit-center;
  }

  /* body {
    background-image:url('../../../public/assets/images/bgrecognition.png') ;
    background-repeat: no-repeat;
    background-position: bottom right;
    
    background-size: 500px;
  } */
  .slide-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    vertical-align: middle;
    place-content: center;
  }
 
  .slide-component {
    width:493px;
     border:2px solid black;
     padding-bottom:30px;
      border-radius:26px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.50);
  }

  .container {
    height: 100vh;
  }