.CamViewer-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;    
}

.CamViewer-section{
width: 600px;
height: 400px;
position: relative;

}

.video {
    border-radius: 26px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}